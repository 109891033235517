import React from 'react';
import cn from 'classnames';

// types
import { IFormatExtenderPreloader } from '../../../types/studio-format-extender.types';

// styles
import '../../../styles/studio/format-extender.scss';

const FormatsExtenderPreloader = ({ isDarkMode }: IFormatExtenderPreloader) => {
  return (
    <div className="format-extender-preloader">
      <p
        className={cn('format-extender-preloader--title', {
          'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
        })}
      >
        creating formats...
      </p>
      <p
        className={cn('format-extender-preloader--subtitle', {
          'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
        })}
      >
        creating new design files...please wait and don’t exit or refresh the
        page.
      </p>
    </div>
  );
};

export default FormatsExtenderPreloader;
