import React from 'react';
import cn from 'classnames';

// types
import { IFormatExtenderProject } from '../../types/studio-format-extender.types';

// styles
import '../../styles/shared/project.scss';

const Project = ({
  project,
  handleSelectProject,
  selectedProject,
  additionalTextClass,
  isDarkMode,
}: IFormatExtenderProject) => {
  const onHandleSelectProject = () => {
    if (handleSelectProject) {
      handleSelectProject(project);
    }
  };

  return (
    <div
      className={cn('project', {
        active: selectedProject?.id === project?.id,
        'light-mode-active': !isDarkMode && selectedProject?.id === project?.id,
      })}
      onClick={onHandleSelectProject}
    >
      <div className="project--image-container">
        <img
          src={project.preview || project.previewThumb}
          alt="project"
          className="project--image-container--image"
        />
      </div>
      <div className="project--title-container">
        <p
          className={cn(
            'project--title-container--title',
            additionalTextClass,
            {
              darken: !isDarkMode && typeof isDarkMode === 'boolean',
            },
          )}
        >
          {project.name}
        </p>
      </div>
    </div>
  );
};

export default Project;
