import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import ProjectsHeader from '../shared/ProjectsHeader';
import FormatExtenderContent from '../shared/FormatExtenderContent';

// api
import {
  deleteGeneratedFormatExtenderPageFormats,
  getGeneratedFormatExtenderPageFormats,
  saveGeneratedFormatExtenderPageFormats,
} from '../../api/helpers';

// types
import { IProjectsTabContent, IStudioProject } from '../../types/studio.types';
import { PageFormatsInfoType } from '../../types/studio-format-extender.types';

const ProjectsFormatExtenderTab = ({
  searchParams,
  setSearchParams,
  isDarkMode,
  toggleDarkMode,
}: IProjectsTabContent) => {
  const [formatExtenderStep, setFormatExtenderStep] = useState(0);
  const [selectedProject, setSelectedProject] = useState<IStudioProject | null>(
    null,
  );
  const [generatedPageFormatsInfo, setGeneratedPageFormatsInfo] = useState(
    {} as PageFormatsInfoType,
  );

  const navigate = useNavigate();

  useEffect(() => {
    getGeneratedFormatExtenderPageFormats().then((resp) => {
      if (resp.generatedProjects && resp.generatedProjects.length) {
        setGeneratedPageFormatsInfo(resp);
        setFormatExtenderStep(3);
      } else {
        setFormatExtenderStep(1);
      }
    });
  }, []);

  const onProceedFirstStep = () => {
    setFormatExtenderStep(2);
  };

  const triggerPreloader = () => {
    setFormatExtenderStep(2.5);
  };

  const onProceedSecondStep = () => {
    setFormatExtenderStep(3);
    setSelectedProject(null);
  };

  const setFirstFormatExtenderStep = () => {
    setFormatExtenderStep(1);
    setSelectedProject(null);
  };

  const onSaveGeneratedPageFormats = (pageFormatsInfo: PageFormatsInfoType) => {
    setGeneratedPageFormatsInfo(pageFormatsInfo);
  };

  const onSelectProject = (project: IStudioProject) => {
    setSelectedProject(project);
  };

  const onGeneratedProjectsSave = () => {
    const projectsSidebarEl = document.getElementById('projects');

    saveGeneratedFormatExtenderPageFormats(generatedPageFormatsInfo.id).then(
      (resp) => {
        projectsSidebarEl && projectsSidebarEl.click();
        navigate({
          pathname: '/tool/studio/',
          search: `?folderId=${resp.resultFolder}`,
        });
      },
    );
  };

  const onGeneratedProjectsCancel = () => {
    deleteGeneratedFormatExtenderPageFormats(generatedPageFormatsInfo.id).then(
      () => {
        setFormatExtenderStep(1);
      },
    );
  };

  return (
    <div className="projects-tab-content">
      <ProjectsHeader
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        title="Format Extender"
      />
      <div className="projects-tab-content--body">
        <FormatExtenderContent
          step={formatExtenderStep}
          selectedProject={selectedProject}
          handleSelectProject={onSelectProject}
          onProceedFirstStep={onProceedFirstStep}
          onProceedSecondStep={onProceedSecondStep}
          onSaveGeneratedPageFormats={onSaveGeneratedPageFormats}
          generatedPageFormatsInfo={generatedPageFormatsInfo}
          onGeneratedProjectsCancel={onGeneratedProjectsCancel}
          triggerPreloader={triggerPreloader}
          onGeneratedProjectsSave={onGeneratedProjectsSave}
          setFirstFormatExtenderStep={setFirstFormatExtenderStep}
          isDarkMode={isDarkMode}
        />
      </div>
    </div>
  );
};

export default ProjectsFormatExtenderTab;
