import React from 'react';

// components
import FormatExtenderContentStep1 from '../StudioEditor/FormatExtender/FormatExtenderContentStep1';
import FormatExtenderContentStep2 from '../StudioEditor/FormatExtender/FormatExtenderContentStep2';
import FormatExtenderContentStep3 from '../StudioEditor/FormatExtender/FormatExtenderContentStep3';
import FormatsExtenderPreloader from '../StudioEditor/FormatExtender/FormatsExtenderPreloader';

// types
import { IFormatExtenderContent } from '../../types/studio-format-extender.types';

const FormatExtenderContent = ({
  step,
  handleSelectProject,
  selectedProject,
  onProceedFirstStep,
  onProceedSecondStep,
  onSaveGeneratedPageFormats,
  generatedPageFormatsInfo,
  onGeneratedProjectsCancel,
  triggerPreloader,
  onGeneratedProjectsSave,
  setFirstFormatExtenderStep,
  isDarkMode,
}: IFormatExtenderContent) => {
  switch (step) {
    case 1: {
      return (
        <FormatExtenderContentStep1
          selectedProject={selectedProject}
          handleSelectProject={handleSelectProject}
          onProceedFirstStep={onProceedFirstStep}
          isDarkMode={isDarkMode}
        />
      );
    }

    case 2: {
      return (
        <FormatExtenderContentStep2
          onProceedSecondStep={onProceedSecondStep}
          selectedProject={selectedProject}
          onSaveGeneratedPageFormats={onSaveGeneratedPageFormats}
          triggerPreloader={triggerPreloader}
          setFirstFormatExtenderStep={setFirstFormatExtenderStep}
          isDarkMode={isDarkMode}
        />
      );
    }

    case 3: {
      return (
        <FormatExtenderContentStep3
          generatedPageFormatsInfo={generatedPageFormatsInfo}
          onGeneratedProjectsCancel={onGeneratedProjectsCancel}
          onGeneratedProjectsSave={onGeneratedProjectsSave}
          isDarkMode={isDarkMode}
        />
      );
    }

    case 2.5: {
      return <FormatsExtenderPreloader isDarkMode={isDarkMode} />;
    }

    default: {
      return null;
    }
  }
};

export default FormatExtenderContent;
