import React, { useState } from 'react';
import cn from 'classnames';

// types
import {
  IPageFormatsSection,
  IPageFormatOption,
} from '../../../types/studio-format-extender.types';

import { InputChangeEventType } from '../../../types/common.types';

// styles
import '../../../styles/studio/format-extender.scss';

const PageFormatOption = ({
  pageFormat,
  onPageFormatSelect,
  isDarkMode,
}: IPageFormatOption) => {
  return (
    <div className="formats-container--dropdown--formats--option">
      <img
        src={pageFormat.originalLogo}
        alt="format icon"
        className="formats-container--dropdown--formats--option--icon"
      />{' '}
      <p
        className={cn('formats-container--dropdown--formats--option--name', {
          'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
        })}
      >
        {pageFormat.name}
      </p>{' '}
      <input
        type="checkbox"
        className={cn(
          'formats-container--dropdown--formats--option--checkbox',
          {
            'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
          },
        )}
        onChange={(event) => onPageFormatSelect(pageFormat, event)}
      />
    </div>
  );
};

const PageFormatsSection = ({
  pageFormats,
  onPageFormatSelect,
  isDarkMode,
}: IPageFormatsSection) => {
  const [query, setQuery] = useState('');

  const onQueryChange = (e: InputChangeEventType) => {
    setQuery(e.target.value);
  };

  const filteredPageFormats = pageFormats.filter((pageFormat) =>
    pageFormat.name.toLowerCase().includes(query.toLowerCase()),
  );

  return (
    <div className="formats-container--dropdown">
      <div className="formats-container--dropdown--input-container">
        <input
          type="text"
          className={cn('formats-container--dropdown--input-container--input', {
            'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
          })}
          placeholder="Search"
          value={query}
          onChange={onQueryChange}
        />
        <img
          src={`/static/img/icons/search-${
            isDarkMode
              ? 'light'
              : typeof isDarkMode !== 'boolean'
              ? 'light'
              : 'dark'
          }.svg`}
          alt="search"
          className="formats-container--dropdown--input-container--icon"
        />
      </div>
      <div
        className={cn('formats-container--dropdown--formats', {
          'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
        })}
      >
        {filteredPageFormats.length ? (
          filteredPageFormats.map((pageFormat) => (
            <PageFormatOption
              key={pageFormat.id}
              pageFormat={pageFormat}
              onPageFormatSelect={onPageFormatSelect}
              isDarkMode={isDarkMode}
            />
          ))
        ) : (
          <p
            className={cn('formats-container--dropdown--formats--no-options', {
              'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
            })}
          >
            No formats
          </p>
        )}
      </div>
    </div>
  );
};

export default PageFormatsSection;
