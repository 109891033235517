import React from 'react';

const ProjectRandomizeP2DIcon = ({ fill = '#BCBCBC' }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 17.5C16.9167 17.5 17.2708 17.3542 17.5625 17.0625C17.8542 16.7708 18 16.4167 18 16C18 15.5833 17.8542 15.2292 17.5625 14.9375C17.2708 14.6458 16.9167 14.5 16.5 14.5C16.0833 14.5 15.7292 14.6458 15.4375 14.9375C15.1458 15.2292 15 15.5833 15 16C15 16.4167 15.1458 16.7708 15.4375 17.0625C15.7292 17.3542 16.0833 17.5 16.5 17.5ZM12.5 13.5C12.9167 13.5 13.2708 13.3542 13.5625 13.0625C13.8542 12.7708 14 12.4167 14 12C14 11.5833 13.8542 11.2292 13.5625 10.9375C13.2708 10.6458 12.9167 10.5 12.5 10.5C12.0833 10.5 11.7292 10.6458 11.4375 10.9375C11.1458 11.2292 11 11.5833 11 12C11 12.4167 11.1458 12.7708 11.4375 13.0625C11.7292 13.3542 12.0833 13.5 12.5 13.5ZM8.5 9.5C8.91667 9.5 9.27083 9.35417 9.5625 9.0625C9.85417 8.77083 10 8.41667 10 8C10 7.58333 9.85417 7.22917 9.5625 6.9375C9.27083 6.64583 8.91667 6.5 8.5 6.5C8.08333 6.5 7.72917 6.64583 7.4375 6.9375C7.14583 7.22917 7 7.58333 7 8C7 8.41667 7.14583 8.77083 7.4375 9.0625C7.72917 9.35417 8.08333 9.5 8.5 9.5ZM5.5 21C4.95 21 4.47917 20.8042 4.0875 20.4125C3.69583 20.0208 3.5 19.55 3.5 19V5C3.5 4.45 3.69583 3.97917 4.0875 3.5875C4.47917 3.19583 4.95 3 5.5 3H19.5C20.05 3 20.5208 3.19583 20.9125 3.5875C21.3042 3.97917 21.5 4.45 21.5 5V19C21.5 19.55 21.3042 20.0208 20.9125 20.4125C20.5208 20.8042 20.05 21 19.5 21H5.5ZM5.5 19H19.5V5H5.5V19Z"
        fill={fill}
      />
    </svg>
  );
};

export default ProjectRandomizeP2DIcon;
