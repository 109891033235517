import { DropdownStylesType } from '../types/bottom-actions.types';

export const darkDropdownStyles = (
  controlStyling = {},
  singleValueStyling = {},
  menuStyling = {},
  placeholderStyling = {},
  optionStyling = {},
): DropdownStylesType => ({
  indicatorSeparator: () => ({ display: 'none' }),
  control: (baseStyles) => ({
    ...baseStyles,
    ...controlStyling,
    background: '#363636',
    border: '0.5px solid #242424',
    width: '100%',
    color: '#EEEEEE',
    boxShadow: 'none',
    ':hover': {
      border: '0.5px solid #242424',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    ...singleValueStyling,
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'initial',
    color: '#EEEEEE',
  }),
  menu: () => ({
    ...menuStyling,
    background: '#363636',
    position: 'absolute',
    width: '100%',
    zIndex: 10000,
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    ...placeholderStyling,
    color: '#ffffff',
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    ...optionStyling,
    position: 'relative',
    backgroundColor: isSelected || isFocused ? '#EAECF0' : undefined,
    paddingRight: 32,
    margin: 6,
    borderRadius: 6,
    width: '100%',
    color: '#EEEEEE',
    background: '#363636',
    textOverflow: 'ellipsis',
    maxWidth: '97%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    ':hover': {
      background: isSelected ? '#363636' : '#ffffff',
      color: isSelected ? '#EEEEEE' : '#000000',
      cursor: isSelected ? 'default' : 'pointer',
    },

    ...(isSelected
      ? {
          ':after': {
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 10,
            margin: 'auto',
            background:
              'center no-repeat url(/static/img/icons/simple-white-tick.svg)',
            content: '" "',
            display: 'block',
            height: 14,
            width: 14,
          },
        }
      : {}),
  }),
});

export const SUGGESTIONS_ARRAY = [
  {
    id: 0,
    message: 'Marketing my new online course on mastering photography',
  },
  {
    id: 1,
    message: 'Promoting the launch of my eco-friendly reusable water bottles',
  },
  {
    id: 2,
    message:
      'Spreading awareness of the impact of plastic pollution on marine life',
  },
  {
    id: 3,
    message:
      'Celebrating a client’s success story who used our marketing strategy to triple their sales',
  },
  {
    id: 4,
    message:
      'Sharing key insights about building an effective personal brand on LinkedIn',
  },
  {
    id: 5,
    message: 'Sharing strategies to improve team collaboration in remote work',
  },
  {
    id: 6,
    message: 'Reminding my followers of my upcoming yoga retreat in Bali',
  },
  {
    id: 7,
    message: 'Promoting our exclusive hand-painted sneakers for sneakerheads',
  },
  {
    id: 8,
    message:
      'Sharing a customer review highlighting our top-notch 24/7 support',
  },
  {
    id: 9,
    message: 'Educating users on simple steps to reduce household energy waste',
  },
  {
    id: 10,
    message:
      'Advertising my fitness tracker app with personalized coaching features',
  },
  {
    id: 11,
    message: 'Showcasing my newest collection of handcrafted ceramic mugs',
  },
  {
    id: 12,
    message: 'Announcing the latest episode on my true crime podcast',
  },
  {
    id: 13,
    message:
      'Promoting the flash sale of my organic skincare line, ending in 24 hours',
  },
  {
    id: 14,
    message:
      'Spreading awareness of how to support local businesses during tough times',
  },
  {
    id: 15,
    message: 'Showcasing our new waterproof backpack for adventurous travelers',
  },
  {
    id: 16,
    message:
      'Delivering a special birthday discount to Sarah for 15% off her favorite skincare items',
  },
  {
    id: 17,
    message:
      'Reminding Javier about his upcoming dentist appointment on Friday at 2 PM',
  },
  {
    id: 18,
    message:
      'Wishing the user a happy New Year from the team at [coolab.ai](http://coolab.ai)',
  },
  {
    id: 19,
    message:
      'Sharing a behind-the-scenes look at creating our artisanal chocolate bars',
  },
  {
    id: 20,
    message: 'Comparing of the top three cars for city commuting',
  },
  {
    id: 21,
    message:
      'Sharing step-by-step guide on how to bake the perfect sourdough bread',
  },
  {
    id: 22,
    message:
      'Sharing practical tips on growing your social media following organically',
  },
  {
    id: 23,
    message: 'Marketing our personalized wedding invitation design service',
  },
  {
    id: 24,
    message: 'Promoting the pre-order of our limited-edition vinyl records',
  },
  {
    id: 25,
    message:
      'Spreading awareness of how urban gardening can improve air quality',
  },
  {
    id: 26,
    message:
      'Celebrating a milestone with our user who achieved their fitness goals using our app',
  },
  {
    id: 27,
    message:
      'Sharing actionable advice for small businesses to optimize their marketing budgets',
  },
  {
    id: 28,
    message: 'Marketing my online course on mastering UX/UI design',
  },
  {
    id: 29,
    message: 'Promote the launch of our new AI-powered logo design tool',
  },
  {
    id: 30,
    message:
      'Spreading awareness about the importance of accessibility in web design',
  },
  {
    id: 31,
    message:
      'Celebrating a client’s journey of scaling their e-commerce store with our platform',
  },
  {
    id: 32,
    message:
      'Sharing tips for creating eye-catching product photography for online stores',
  },
  {
    id: 33,
    message:
      'Sharing advice on choosing the best color schemes for modern websites',
  },
  {
    id: 34,
    message:
      'Reminding followers of our upcoming webinar on the future of digital design trends',
  },
  {
    id: 35,
    message:
      'Promoting our custom website templates designed for small businesses',
  },
  {
    id: 36,
    message:
      'Sharing a user’s success story about doubling their sales using our digital marketing services',
  },
  {
    id: 37,
    message:
      'Educating users on how to optimize their e-commerce site for mobile shoppers',
  },
  {
    id: 38,
    message:
      'Advertising our app for tracking and analyzing website visitor data',
  },
  {
    id: 39,
    message:
      'Showcasing our latest tech gadget: a portable wireless charging pad',
  },
  {
    id: 40,
    message:
      'Announcing the latest episode on my podcast discussing AI in the workplace',
  },
  {
    id: 41,
    message:
      'Spreading awareness of how digital tools can empower small businesses',
  },
  {
    id: 42,
    message:
      'Showcasing our new 3D design software for architects and creators',
  },
  {
    id: 43,
    message:
      'Delivering a personalized offer to Josh for 20% off his next custom project with us',
  },
  {
    id: 44,
    message:
      'Reminding users of our free upcoming demo of the new e-commerce shipping plugin',
  },
  {
    id: 45,
    message:
      'Wishing the community a productive start to the year with a free design toolkit',
  },
  {
    id: 46,
    message:
      'Showing a comparison of the best graphic tablets for creative professionals',
  },
  {
    id: 47,
    message: 'Recapping of our conference on emerging digital marketing trends',
  },
  {
    id: 48,
    message:
      'Advertising a tutorial on creating responsive designs for websites',
  },
  {
    id: 49,
    message:
      'Marketing our premium subscription for unlimited access to digital design assets',
  },
  {
    id: 50,
    message:
      'Promoting the early bird registration for our design-focused hackathon',
  },
  {
    id: 51,
    message:
      'Spreading awareness about the ethical considerations of AI in design',
  },
  {
    id: 52,
    message:
      'Celebrating a customer’s success with a visually stunning website designed using our tools',
  },
  {
    id: 53,
    message:
      'Sharing best practices for integrating AR experiences into e-commerce platforms',
  },
];

export const robotoTypeface = {
  name: 'Roboto',
  fonts: [
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Black.ttf',
      subFamily: 'Black',
      weight: 'heavy',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-BlackItalic.ttf',
      subFamily: 'Black Italic',
      weight: 'heavy',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Bold.ttf',
      subFamily: 'Bold',
      weight: 'bold',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-BoldItalic.ttf',
      subFamily: 'Bold Italic',
      weight: 'bold',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Italic.ttf',
      subFamily: 'Italic',
      weight: 'normal',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Light.ttf',
      subFamily: 'Light',
      weight: 'light',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-LightItalic.ttf',
      subFamily: 'Light Italic',
      weight: 'light',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Medium.ttf',
      subFamily: 'Medium',
      weight: 'medium',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-MediumItalic.ttf',
      subFamily: 'Medium Italic',
      weight: 'medium',
      style: 'italic',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Regular.ttf',
      subFamily: 'Regular',
      weight: 'normal',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-Thin.ttf',
      subFamily: 'Thin',
      weight: 'thin',
      style: 'normal',
    },
    {
      uri: 'https://cdn.img.ly/assets/v3/ly.img.typeface/fonts/Roboto/Roboto-ThinItalic.ttf',
      subFamily: 'Thin Italic',
      weight: 'thin',
      style: 'italic',
    },
  ],
};

export const imageReplacementTypes = [
  {
    id: 0,
    label: 'Default',
    value: 'default',
  },
  {
    id: 1,
    label: 'Use generated images',
    value: 'generated_images',
  },
  {
    id: 2,
    label: 'Use internal images',
    value: 'internal_images',
  },
  {
    id: 3,
    label: 'Use external images',
    value: 'external_images',
  },
  {
    id: 4,
    label: 'By subject relevance',
    value: 'by_subject_relevance',
  },
];
