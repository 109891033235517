import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import cn from 'classnames';

// components
import PageFormatsSection from './PageFormatsSection';
import SelectedPageFormats from './SelectedPageFormats';

// helpers
import { getToasterOptions } from '../../../helpers/toaster';

// api
import { getPageFormats } from '../../../api/assets';
import {
  generateFormatExtenderPageFormats,
  getGeneratedFormatExtenderPageFormats,
} from '../../../api/helpers';

// types
import {
  IFormatExtenderContentStep2,
  PageFormat,
} from '../../../types/studio-format-extender.types';
import { InputChangeEventType } from '../../../types/common.types';

// styles
import '../../../styles/studio/format-extender.scss';

const FormatExtenderContentStep2 = ({
  onProceedSecondStep,
  selectedProject,
  onSaveGeneratedPageFormats,
  triggerPreloader,
  setFirstFormatExtenderStep,
  isDarkMode,
}: IFormatExtenderContentStep2) => {
  const [pageFormats, setPageFormats] = useState([]);
  const [selectedPageFormats, setSelectedPageFormats] = useState<PageFormat[]>(
    [],
  );

  useEffect(() => {
    getPageFormats()
      .then((innerPageFormats) => {
        setPageFormats(innerPageFormats);
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred with page formats',
          getToasterOptions(),
        );
      });
  }, []);

  const onPageFormatSelect = (
    pageFormat: PageFormat,
    event: InputChangeEventType,
  ) => {
    const updatedData = event.target.checked
      ? [...selectedPageFormats, pageFormat]
      : selectedPageFormats.filter(
          (innerPageFormat: PageFormat) => innerPageFormat.id !== pageFormat.id,
        );

    setSelectedPageFormats(updatedData);
  };

  const onGenerateFormatExtender = async () => {
    const response = await getGeneratedFormatExtenderPageFormats();

    if (response.status == 'failed') {
      toast.error(
        'Format extender process failed. Please try again.',
        getToasterOptions(),
      );
    } else if (response.status === 'in_progress') {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await onGenerateFormatExtender();
    } else if (response.status === 'finished') {
      onSaveGeneratedPageFormats(response);
      onProceedSecondStep();
    }
  };

  const onGenerateNewPageFormats = () => {
    if (!selectedPageFormats.length) {
      return;
    }

    const selectedPageFormsIds = selectedPageFormats.map(
      (pageFormat) => pageFormat.id,
    );

    triggerPreloader();
    generateFormatExtenderPageFormats({
      parentProject: selectedProject?.id,
      formats: selectedPageFormsIds,
    })
      .then(() => {
        onGenerateFormatExtender();
      })
      .catch(() => {
        toast.error(
          'Ooooops, error occurred while creating page formats',
          getToasterOptions(),
        );

        setFirstFormatExtenderStep();
      });
  };

  return (
    <div className="format-extender--content">
      <div className="formats-container">
        <PageFormatsSection
          pageFormats={pageFormats}
          onPageFormatSelect={onPageFormatSelect}
          isDarkMode={isDarkMode}
        />
        <SelectedPageFormats
          selectedPageFormats={selectedPageFormats}
          isDarkMode={isDarkMode}
        />
      </div>
      <div className="format-extender--btn-container">
        <span
          className={cn('projects-content--btn-container--wrapper', {
            disabled: !selectedPageFormats.length,
          })}
        >
          <button
            type="button"
            className={cn(
              'projects-content--btn-container--wrapper--proceed-btn',
              {
                disabled: !selectedPageFormats.length,
                'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
              },
            )}
            onClick={onGenerateNewPageFormats}
          >
            next
          </button>
        </span>
      </div>
    </div>
  );
};

export default FormatExtenderContentStep2;
