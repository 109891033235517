import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSearchParams } from 'react-router-dom';

// components
import ProjectsTabContent from './ProjectsTab';
import ProjectsPromptToDesignTab from './ProjectsPromptToDesignTab';
import ProjectsFormatExtenderTab from './ProjectsFormatExtenderTab';

// icons
import ProjectsFolderIcon from '../../icons/projects/ProjectsFolderIcon';
import ProjectsP2DIcon from '../../icons/projects/ProjectsP2DIcon';
import ProjectsFormatExtenderIcon from '../../icons/projects/ProjectsFormatExtenderIcon';
import ProjectsBrandRoomIcon from '../../icons/projects/ProjectsBrandRoomIcon';
import ProjectsHelpTooltipIcon from '../../icons/projects/ProjectsHelpTooltipIcon';

// redux
import { useAppDispatch } from '../../helpers/hooks';
import { actions as profileActions } from '../../ducks/profile';

// api
import { getProfile } from '../../api/profile';

// styling
import '../../styles/projects/index.scss';
import 'react-tabs/style/react-tabs.scss';

const Projects = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('dark_mode_is_enabled') === 'true',
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchUserProfile = async () => {
      const response = await getProfile();
      dispatch(profileActions.setProfile(response));
    };

    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectTab = (tabIndex: number) => {
    searchParams.set('projectsActiveTab', `${tabIndex}`);
    setSearchParams(searchParams);
  };

  const toggleDarkMode = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    setIsDarkMode((prevState) => {
      if (prevState) {
        document.documentElement.classList.remove('dark');
      } else {
        document.documentElement.classList.add('dark');
      }

      localStorage.setItem('dark_mode_is_enabled', `${!prevState}`);

      return !prevState;
    });
  };

  return (
    <Tabs
      defaultIndex={Number(searchParams.get('projectsActiveTab')) || 0}
      onSelect={onSelectTab}
      className="projects-container"
    >
      <div className="projects-container--sidebar dark:!bg-[#3b3b3b]">
        <div>
          <a href="/">
            <img
              src={`/static/img/text-logo${isDarkMode ? '-light' : ''}.svg`}
              alt="logo"
              className="projects-container--sidebar--logo"
            />
          </a>
          <TabList className="projects-tab-list">
            <Tab
              className={
                isDarkMode ? 'projects-single-tab-dark' : 'projects-single-tab'
              }
              selectedClassName={
                isDarkMode
                  ? 'projects-selected-tab-dark'
                  : 'projects-selected-tab'
              }
            >
              <ProjectsFolderIcon fill={isDarkMode ? '#eeeeee' : '#484848'} />
              <span>Projects</span>
            </Tab>
            <Tab
              className={
                isDarkMode ? 'projects-single-tab-dark' : 'projects-single-tab'
              }
              selectedClassName={
                isDarkMode
                  ? 'projects-selected-tab-dark'
                  : 'projects-selected-tab'
              }
            >
              <ProjectsP2DIcon fill={isDarkMode ? '#eeeeee' : '#484848'} />
              <span>Prompt-to-Design</span>
            </Tab>
            <Tab
              className={
                isDarkMode ? 'projects-single-tab-dark' : 'projects-single-tab'
              }
              selectedClassName={
                isDarkMode
                  ? 'projects-selected-tab-dark'
                  : 'projects-selected-tab'
              }
            >
              <ProjectsFormatExtenderIcon
                fill={isDarkMode ? '#eeeeee' : '#484848'}
              />
              <span>Format Extender</span>
            </Tab>
            <Tab
              className={
                isDarkMode ? 'projects-single-tab-dark' : 'projects-single-tab'
              }
              selectedClassName={
                isDarkMode
                  ? 'projects-selected-tab-dark'
                  : 'projects-selected-tab'
              }
            >
              <ProjectsBrandRoomIcon
                fill={isDarkMode ? '#eeeeee' : '#484848'}
              />
              <span>Brand Room</span>
            </Tab>
          </TabList>
        </div>
        <div>
          <div className="projects-container--sidebar--pro-plan-container dark:!bg-[#212121]">
            <div className="projects-container--sidebar--pro-plan-container--info">
              <img
                src="/static/img/icons/projects-yellow-like.svg"
                alt="folder"
              />
              <p className="projects-container--sidebar--pro-plan-container--info--text dark:!text-[color:#eeeeee]">
                free plan
              </p>
            </div>
            <a
              href="/pro-plan"
              className="projects-container--sidebar--pro-plan-container--link"
            >
              upgrade
            </a>
          </div>
          <a href="/help/faq">
            <div className="projects-container--sidebar--help-section">
              <ProjectsHelpTooltipIcon
                fill={isDarkMode ? '#eeeeee' : '#484848'}
              />
              <span className="projects-container--sidebar--help-section--text dark:!text-[color:#eeeeee]">
                Help & Feedback
              </span>
            </div>
          </a>
        </div>
      </div>

      <div className="projects-container--content">
        <TabPanel>
          <ProjectsTabContent
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
          />
        </TabPanel>
        <TabPanel>
          <ProjectsPromptToDesignTab
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
          />
        </TabPanel>
        <TabPanel>
          <ProjectsFormatExtenderTab
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
          />
        </TabPanel>
        <TabPanel>
          <h2>Any content 4</h2>
        </TabPanel>
      </div>
    </Tabs>
  );
};

export default Projects;
