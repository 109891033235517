import React from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

// types
import {
  IFormatExtenderContentStep3,
  IGeneratedPageFormat,
} from '../../../types/studio-format-extender.types';

// styles
import '../../../styles/studio/format-extender.scss';

const GeneratedPageFormat = ({
  pageFormat,
  isDarkMode,
}: IGeneratedPageFormat) => {
  const navigate = useNavigate();

  const onEdit = () => {
    navigate({
      pathname: `/tool/scene/${pageFormat.id}/`,
      search: `${
        typeof isDarkMode !== 'boolean'
          ? '?redirectedFromStudio=1'
          : '?origin_type=projects'
      }`,
    });
  };

  const onProjectDownload = async () => {
    try {
      const response = await fetch(pageFormat.preview);
      const blob = await response.blob();

      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', pageFormat.name || 'download.webp');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Release the blob URL
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Download failed:', error);
    }
  };

  return (
    <div
      className={cn('generated-page-formats-container--page-format', {
        'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
      })}
    >
      <p className="generated-page-formats-container--page-format--title">
        {pageFormat.formatName}
      </p>
      <div className="generated-page-formats-container--page-format--image-container">
        <img
          src={pageFormat.preview}
          alt="project"
          className="generated-page-formats-container--page-format--image-container--image"
        />
      </div>
      <div className="generated-page-formats-container--page-format--actions-container">
        <button
          type="button"
          className={cn(
            'generated-page-formats-container--page-format--actions-container--button',
            {
              'light-mode': !isDarkMode,
            },
          )}
          onClick={onEdit}
        >
          <img src="/static/img/icons/edit-white.svg" alt="edit" />
          <span className="generated-page-formats-container--page-format--actions-container--button--title">
            Edit
          </span>
        </button>
        <button
          type="button"
          className={cn(
            'generated-page-formats-container--page-format--actions-container--button',
            {
              'light-mode': !isDarkMode,
            },
          )}
          onClick={onProjectDownload}
        >
          <img src="/static/img/icons/download-white.svg" alt="download" />
          <span className="generated-page-formats-container--page-format--actions-container--button--title">
            Download
          </span>
        </button>
      </div>
    </div>
  );
};

const FormatExtenderContentStep3 = ({
  generatedPageFormatsInfo,
  onGeneratedProjectsCancel,
  onGeneratedProjectsSave,
  isDarkMode,
}: IFormatExtenderContentStep3) => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="generated-page-formats-container">
        {generatedPageFormatsInfo.generatedProjects.map((pageFormat) => (
          <GeneratedPageFormat
            key={pageFormat.id}
            pageFormat={pageFormat}
            isDarkMode={isDarkMode}
          />
        ))}
      </div>
      <div className="generated-page-formats-container--btns-container">
        <button
          type="button"
          className={cn(
            'generated-page-formats-container--btns-container--btn cancel',
            {
              'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
              projects: typeof isDarkMode === 'boolean',
            },
          )}
          onClick={onGeneratedProjectsCancel}
        >
          add more formats
        </button>
        <button
          type="button"
          className={cn(
            'generated-page-formats-container--btns-container--btn',
            {
              'light-mode': !isDarkMode && typeof isDarkMode === 'boolean',
              projects: typeof isDarkMode === 'boolean',
            },
          )}
          onClick={onGeneratedProjectsSave}
        >
          Save ({generatedPageFormatsInfo.generatedProjects.length}) formats
        </button>
      </div>
    </div>
  );
};

export default FormatExtenderContentStep3;
