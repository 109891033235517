import React, { useState, useRef } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// components
import ProjectsHeader from '../shared/ProjectsHeader';

// data
import { SUGGESTIONS_ARRAY } from '../../helpers/common.data';

// api
import {
  createPromptToDesign,
  generatePromptToDesign,
  getPromptToDesign,
} from '../../api/helpers';

// icons
import ProjectsRandomizeP2DIcon from '../../icons/projects/ProjectsRandomizeP2DIcon';

// studio
import { IProjectsTabContent } from '../../types/studio.types';

// styles
import '../../styles/projects/projects-prompt-to-design-tab.scss';

interface IPromptToDesignForm {
  purpose: string;
}

const ProjectsPromptToDesignTab = ({
  searchParams,
  setSearchParams,
  isDarkMode,
  toggleDarkMode,
}: IProjectsTabContent) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [generatingProgress, setGeneratingProgress] = useState(1);
  const [generatingStage, setGeneratingStage] = useState('');
  const [p2dIsGenerating, setIsP2dGenerating] = useState(false);

  const inputContainerRef = useRef(null);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    watch,
  } = useForm<IPromptToDesignForm>();

  const inputMatchP2dFormat = watch('purpose');

  const onInputFocus = () => {
    setIsInputFocused(true);
  };

  const onInputBlur = () => {
    setIsInputFocused(false);
  };

  const onP2dRandomize = () => {
    if (inputContainerRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (inputContainerRef.current as any).childNodes[0].focus();
    }
    setIsInputFocused(true);
    setValue(
      'purpose',
      SUGGESTIONS_ARRAY[Math.floor(Math.random() * 10)].message,
      {
        shouldValidate: true,
      },
    );
  };

  const onGeneratePromptToDesign = async (id: number) => {
    const response = await getPromptToDesign(id);

    if (response.progress > generatingProgress) {
      setGeneratingProgress(response.progress);
      setGeneratingStage(response.progressStage);
      // eslint-disable-next-line no-use-before-define
      stopGeneratingInterval();
    }

    const generatingProgressInterval = setInterval(() => {
      setGeneratingProgress((prevState) =>
        prevState === response.nextStepProgress ? prevState : prevState + 1,
      );
    }, 1000);

    function stopGeneratingInterval() {
      clearInterval(generatingProgressInterval);
    }

    if (response.status == 'failed') {
      setIsP2dGenerating(false);
      setError('purpose', {
        message: 'Prompt to design process failed. Please try again.',
      });
    } else if (response.status === 'created') {
      setIsP2dGenerating(false);
    } else if (response.status === 'in_progress') {
      setIsP2dGenerating(true);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await onGeneratePromptToDesign(id);
    } else if (response.status === 'finished') {
      stopGeneratingInterval();

      navigate({
        pathname: `/tool/scene/${response.userProjectId}`,
        search: `?origin_type=p2d&p2dId=${response.id}`,
      });

      await new Promise((resolve) => {
        setTimeout(resolve, 500);
      });
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  };

  const onCreatePromptToDesign = async (data: IPromptToDesignForm) => {
    try {
      const createP2DResponse = await createPromptToDesign({
        purpose: data.purpose,
      });

      const generatePromptToDesignData = { template: null };
      await generatePromptToDesign(
        createP2DResponse.id,
        generatePromptToDesignData,
      );

      onGeneratePromptToDesign(createP2DResponse.id);
    } catch (error) {
      setError('purpose', {
        message: 'Server is currently under heavy load, please try again later',
      });
    }
  };

  const onFormSubmit = (data: IPromptToDesignForm) => {
    onCreatePromptToDesign(data);
  };

  return (
    <div className="projects-tab-content">
      <ProjectsHeader
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        title="Prompt-to-Design"
        additionalTitleClass="p2d-title"
      />
      <div className="projects-tab-content--body">
        <div className="projects-p2d-container">
          <p className="projects-p2d-container--title dark:!text-[color:#eeeeee]">
            WHAT kind of social post are you making?
          </p>
          <div className="projects-p2d-container--formats-container">
            <p className="projects-p2d-container--formats-container--hint dark:!text-[color:#eeeeee]">
              in any format for
            </p>
            <div className="projects-p2d-container--formats-container--formats">
              <img
                src="../../../../static/img/icons/facebook-original-logo.svg"
                alt="facebook"
              />
              <img
                src={`../../../../static/img/icons/x${
                  isDarkMode ? '' : '-dark'
                }(ex-twitter).svg`}
                alt="twitter"
              />
              <img
                src="../../../../static/img/icons/linkedin-original-logo.svg"
                alt="linkedin"
              />
              <img
                src="../../../../static/img/icons/tiktok-original-logo.svg"
                alt="tiktok"
              />
              <img
                src="../../../../static/img/icons/youtube-original-logo.svg"
                alt="youtube"
              />
              <img
                src="../../../../static/img/icons/snapchat-original-logo.svg"
                alt="youtube"
              />
              <img
                src="../../../../static/img/icons/pinterest-original-logo.svg"
                alt="pinterest"
              />
              <img
                src="../../../../static/img/icons/twitch-original-logo.svg"
                alt="twitch"
              />
              <img
                src="../../../../static/img/icons/instagram-original-logo.svg"
                alt="instagram"
              />
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onFormSubmit)}
            className="p2d-container--content form"
          >
            <div className="projects-p2d-container--input-container">
              <div
                className="projects-p2d-container--input-container--randomize-btn dark:!bg-[#363636] dark:hover:!bg-[#484848] dark:!border-transparent"
                onClick={onP2dRandomize}
              >
                <ProjectsRandomizeP2DIcon
                  fill={isDarkMode ? '#eeeeee' : '#BCBCBC'}
                />
              </div>
              <div
                className="projects-p2d-container--input-container--input-inner-container"
                ref={inputContainerRef}
              >
                <input
                  placeholder="Design a {FORMAT} for a {SUBJECT}"
                  className={cn(
                    'projects-p2d-container--input-container--input-inner-container--input dark:!bg-[#2d2d2d]',
                    {
                      focused: isInputFocused,
                      'focused-dark': isInputFocused && isDarkMode,
                    },
                  )}
                  {...register('purpose')}
                  onFocus={onInputFocus}
                  onBlur={onInputBlur}
                  maxLength={150}
                />
              </div>
            </div>
            {errors?.purpose && (
              <p className="p2d-container--content input-error-msg">
                {errors?.purpose.message}
              </p>
            )}
            {p2dIsGenerating && (
              <>
                <div
                  className="p2d-confirmation-modal-preloader-container"
                  style={{
                    width: `${generatingProgress}%`,
                    background: isDarkMode ? '#eeeeee' : '#888888',
                  }}
                />
                <p className="p2d-confirmation-modal-preloader-container--stage dark:!text-[#eeeeee]">
                  {generatingStage}
                </p>
              </>
            )}
            <div className="p2d-container--content--actions-container">
              <button
                type="submit"
                className={cn(
                  'p2d-container--content--actions-container--submit-btn',
                  {
                    disabled: !inputMatchP2dFormat,
                  },
                )}
              >
                start
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProjectsPromptToDesignTab;
